@media only screen and (max-width: 1080px) {
    hr {
        width: 17em;
    }

    .nav {
        font-size: 40px;
    }

    .nav a:hover {
        text-shadow: 0 0 2px #ffffff, 0 0 3px #ffffff;
        transition: 0.5s;
    }

    .wrapper{
        width: 100%;
        margin-left:0%;
        margin-right:0%;
    }

    #memberTable {
        margin: auto;
        font-size: 1.2em;
    }

    #AboutContainer {
        margin-left: 10%;
        margin-right: 10%;
    }

    .BandPhoto {
        width: 80%;
        margin-bottom: 2em;
        margin-top: 2em;
    }


    #MusicContainer {
        width: 80%;
    }


    .reviewContainer {
    }

    .gigtable {
        max-width: 75vw;
        width: 75vw;
        overflow-wrap: break-word;
    }

	.gigtable tr td:nth-of-type(4){
		width: unset;
	}
}
