@media only screen and (max-width: 680px) {

    body {
        overflow-x: hidden;
    }
    .wrapper{
        width: 100%;
        margin-left:0%;
        margin-right:0%;
        font-size: smaller;
    }

    hr {
        width: 100%;
    }

    p {
        font-size: smaller;
    }

    hr {
        width: 17em;
    }

    .nav {
        width: 100vw;
        font-size: 26px;
    }

    .navcontent {
        margin-top: 5px;
    }

    .showMore {
        font-size: small;
    }

    .BandPhoto {
        width: 90%;
        margin-bottom: 2em;
        margin-top: 2em;
        border: none;
    }

    #memberTable {
        font-size: 1.3em;
    }

    .reviewContainer {
        width: 100%;
        margin-left: -2%;
    }

    #MusicContainer {
        width: 90%;
    }

    .gigtable {
        max-width: 85vw;
        width: 85vw;
        margin-left: 2%;
        font-size: small;
        overflow-wrap: break-word;
    }

    .gigtable th {
    font-size: small;
    }

    .gigtable {
        margin-top: 9%;
    }

    .gigtable td {
        font-size: smaller;
    }
}
