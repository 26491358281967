@import url(https://fonts.googleapis.com/css?family=PT+Sans+Narrow:400);
.wrapper {
  background-color: white;
  color: black;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 15%;
}

body {
  font-family: 'Georgia', Times, serif;
  font-size: 22px;
  color: white;
  width: auto;
  background-color: white;
  overflow-x: hidden;
  margin: auto auto;
}

p {
  color: black;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline;
  margin: 0 4%;
}

td {
  text-align: left;
  padding-right: 10px;
  vertical-align: top;
  font-size: 80%;
  padding-bottom: 5px;
}

h1 {
  font-size: 200%;
  font-family: serif;
  line-height: 10px;
  margin-top: 75px;
  margin-bottom: 75px;
  text-align: center;
}

h3 {
  font-size: 155%;
  margin-bottom: 1%;
  color: black;
  text-align: center;
}

h4 {
  font-size: 110%;
  margin-bottom: 1%;
  color: black;
  text-align: left;
}

a:hover {
}

hr {
  margin-bottom: 2%;
  margin-top: 2%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 30em;
  color: #dddddd;
}

.showMore {
  color: #858585;
  font-style: italic;
  font-size: large;
  margin-top: 3%;
  margin-bottom: 3%;
  cursor: pointer;
}

.showMore:hover {
  color: black;
}

.nav {
  background-color: rgb(15, 15, 15);
  width: 100%;
  position: fixed;
  min-height: 36px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 50px;
  cursor: pointer;
  z-index: 55;
}
.nav a:hover {
  text-shadow: 0 0 2px #ffffff, 0 0 3px #ffffff;
  transition: 0.5s;
}
.navcontent {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

/* Make sure that a-href jumps to section ands still shows the title. */
#About {
  scroll-margin-top: 100px;
}

#AboutContainer {
  height: 100%;
  color: black;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: 25%;
  margin-right: 25%;
}

#memberTable {
  margin: auto;
  font-size: 1.1em;
}

.socialLinks {
  color: grey;
}

.socialLinks:hover {
  color: black;
}

.backgroundIMG {
  margin-top: 60px;
  width: 100vw;
  background-size: cover;
  background-attachment: absolute;
  background-repeat: no-repeat;
}

.BandDiv {
  max-width: 100vw;
  width: 100vw;
  margin-left:  calc(50% - 50vw);
  display: block;
  margin-bottom: auto;
  margin-top: auto;
}

.BandPhoto {
  margin: 0 auto;
  margin-bottom: -5px;
  display: block;
  padding: 8px 8px;
  border: 2px solid #999;
  float: none;
  clear: both;
  width: 50%;
}

.AlbumPhoto {
  margin: 30px auto;
  display: block;
  float: none;
  clear: both;
  width: 60%;
}

.AlbumInfoMain {
  font-size: medium;
  text-align: center;
  font-weight: bold;
}

.AlbumInfoDetails {
  font-size: medium;
}

#Music {
  margin-top: 5%;
  scroll-margin-top: 100px;
}

#MusicContainer {
  text-align: center;
  height: 100%;
  width: 60%;
  margin-top: 5%;
  margin-bottom: 3%;
  margin-left: auto;
  margin-right: auto;
}


.reviewContainer {
  margin-top: -10%;
  margin-bottom: 6em;
  color: black;
}

.leftContent {
  height: 100%;
  color: black;
  text-align: left;
  margin-bottom: 3%;
  padding: 2%;
  width: 46%;;
  float: left;
}

.rightContent {
  height: 100%;
  color: black;
  text-align: left;
  margin-bottom: 3%;
  padding: 2%;
  width: 46%;;
  float: left;
}

.reviewLink {
  font-size: 90%;
}

.reviewLink {
  font-style: italic;
}

.reviewLink a:hover {
  text-decoration: underline;
}

.reviewLink h4 {
  font-size: large;
  font-style: normal;
  margin-bottom: -0.7em;
}

#Gigs {
  scroll-margin-top: 100px;
  display: block;
  margin-top:12%;
  margin-left: auto;
  margin-right: auto;
}

.gigtable {
  border-collapse: collapse;
  width: 60%;
  margin-left: auto;
  margin-top: 5%;
  margin-right: auto;
  position: relative;
  left: 5%;
}

.gigtable td div {
  min-height: 50px;
  word-wrap: 'break-word';
}

.gigtable th:nth-of-type(1) {
  text-decoration: none;
  padding-right: 10px;
  width: 2.5%;
}

.gigtable tr th:nth-of-type(2) {
  width: 9%;
}

.gigtable tr th:nth-of-type(3) {
  width: 8%;
}

.gigtable tr th:nth-of-type(4) {
  width: 10%;
}

.gigtable th {
  font-size: large;
  text-align: left;
  white-space: nowrap;
  padding-bottom: 2px;
}
